import React, { useEffect, useState } from "react"
import "./pagination.scss"
import links from "../../utils/links"
import index from "../../pages/diagnostic"
import config from "../../utils/config"

const pagination = ({
  data,
  handleChange,
  addClass = "",
  conseil = false,
  slug = "",
  prePagination,
  midPagination,
  postPagination
}) => {
    if (conseil && data) {    
      return (
        <>
          {data?.tabs && (
            <>
              {data?.tabs.length == 1 && data?.tabs.includes(1) ? (
                <></>
              ) : (
                <div className={"block-pagination d-f " + addClass}>
                  <ul className="ul d-f ai-c">
                    {prePagination && prePagination}
                    {midPagination && midPagination}
                    {postPagination && postPagination}
                  </ul>
                </div>
              )}
            </>
          )}
        </>
      )
    } else {
      return (
        <>
          {data?.tabs && (
            <>
              {data?.tabs.length == 1 && data?.tabs.includes(1) ? (
                <></>
              ) : (
                <div className={"block-pagination d-f " + addClass}>
                  <ul className="ul d-f ai-c">
                    <li
                      className={
                        data.prev === null
                          ? "li icon-arrow-pagination desabled mr-1"
                          : "li icon-arrow-pagination mr-1"
                      }
                    >
                      <button
                        className={
                          data.prev === null
                            ? "d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next"
                            : "d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next is-active"
                        }
                        style={{
                          height: "15px",
                          width: "25px",
                          transform: "rotate(180deg)",
                        }}
                        onClick={() => handleChange(data.prev)}
                      ></button>
                    </li>
                    {/* {data.prev !== null && (
                      <li className="li">
                        <button
                          className="d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next"
                          style={{
                            height: "15px",
                            width: "25px",
                            transform: "rotate(180deg)",
                          }}
                          onClick={() => handleChange(data.prev)}
                        ></button>
                      </li>
                    )} */}
                    {data.tabs.map((el, index) => (
                      <li className="ls-n px-1" key={index}>
                        <button
                          className={
                            data.currentPage === el
                              ? "page-item  ff-gb is-active"
                              : "page-item ff-gb "
                          }
                          onClick={() => handleChange(el)}
                        >
                          <span>{el}</span>
                        </button>
                      </li>
                    ))}
                    {data.tabNext !== null && (
                      <li className="ls-n px-1 d-f ai-c">
                        <button
                          className="px-0 "
                          onClick={() => handleChange(data.tabNext)}
                        >
                          <div className="d-f ai-c">
                            <span
                              style={{
                                height: "3px",
                                width: "3px",
                                marginRight: "2px",
                                marginLeft: "2px",
                              }}
                              className="bcg-12 br-50"
                            ></span>
                            <span
                              style={{
                                height: "3px",
                                width: "3px",
                                marginRight: "2px",
                                marginLeft: "2px",
                              }}
                              className="bcg-12 br-50"
                            ></span>
                            <span
                              style={{
                                height: "3px",
                                width: "3px",
                                marginRight: "2px",
                                marginLeft: "2px",
                              }}
                              className="bcg-12 br-50"
                            ></span>
                          </div>
                        </button>
                      </li>
                    )}
                    <li
                      className={
                        data.next === null
                          ? "li icon-arrow-pagination desabled ml-1"
                          : "li icon-arrow-pagination ml-1"
                      }
                    >
                      <button
                        className={
                          data.next === null
                            ? "d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next"
                            : "d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next is-active"
                        }
                        style={{ height: "15px", width: "25px" }}
                        onClick={() => handleChange(data.next)}
                      ></button>
                    </li>
                    {/* {data.next !== null && (
                      <li className="li">
                        <button
                          className="d-f px-0  ml-1 ai-c jc-c icon-arrow-pagination-next"
                          style={{ height: "15px", width: "25px" }}
                          onClick={() => handleChange(data.next)}
                        ></button>
                      </li>
                    )} */}
                  </ul>
                </div>
              )}
            </>
          )}
        </>
      )
    }
}

export default pagination
